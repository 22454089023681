<!-- 非ca用户企业信息 -->
<template>
  <div class="enterprise">
    <el-card>
      <h3 style="display: flex;">
        <div style="height: 25px;width: 5px;background-color: #409EFF;display: inline-block;"></div>&nbsp;&nbsp;企业认证
      </h3>
      <div style="padding: 20px;" v-if="isperfect">
        <!-- <h4 style="display: flex;margin-left: 50px;">
          <div style="height: 20px;width: 5px;background-color: #409EFF;display: inline-block;"></div>&nbsp;&nbsp;企业信息
        </h4> -->
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="250px"
          class="demo-ruleForm"
          style="width: 55%;margin-left:200px;"
        >
          
          <el-form-item label="企业名称：" prop="firm">
            <el-input v-model="ruleForm.firm"></el-input>
          </el-form-item>
          <el-form-item label="统一社会信用代码：" prop="code">
            <el-input v-model="ruleForm.code"></el-input>
          </el-form-item>
          <el-form-item label="法定代表人姓名：" prop="name">
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>
          <el-form-item label="通讯地址：" prop="area">
            <el-input v-model="ruleForm.area"></el-input>
          </el-form-item>
          <!-- <h4 style="display: flex;margin-left: 50px;">
            <div style="height: 20px;width: 5px;background-color: #409EFF;display: inline-block;"></div>&nbsp;&nbsp;其他信息
          </h4> -->
          <el-form-item label="开户银行：" prop="bank">
            <el-input v-model="ruleForm.bank"></el-input>
          </el-form-item>
          <el-form-item label="开户行账号：" prop="account">
            <el-input v-model="ruleForm.account"></el-input>
          </el-form-item>
          <el-form-item label="法人身份证号：" prop="id">
            <el-input v-model="ruleForm.id"></el-input>
          </el-form-item>
          <!-- <el-form-item label="开户许可证：">
            <div style="display: flex;">
              <span class="befor_2">*</span>
              <el-upload
                class="upload-demo"
                action="api/newGuarantee.do?upLoadEnterpriseInfoFile"
                :on-success="successInfo"
                :data="data1"
                accept=".jpg, .png"
                name="enterpriseInfoFile"
              >
                <el-button>点击上传</el-button>
              </el-upload>
            </div>
          </el-form-item> -->
          <el-form-item label="营业执照：">
            <span class="befor_1">*</span>
            <div style="display: flex;">
              <el-upload
                class="upload-demo"
                
                action="api/newGuarantee.do?upLoadEnterpriseInfoFile"
                :data="data"
                accept=".jpg, .png .bmp"
                :on-success="success"
                name="enterpriseInfoFile"
              >
              <!-- style="width: 202px; height: 270px;" -->
                <el-button>点击上传</el-button>
              </el-upload>
              <!-- <img src="../assets/营业执照.png" alt style="height: 270px;" v-if="isShow" /> -->
              <!-- <div style="margin-left: 20px;">
                <div>注意事项</div>
                <div style="margin-left: 20px;">1、 上传最新营业执照，要保证图片清晰</div>
                <div style="margin-left: 20px;">
                  2、 需进行AI验证，
                  <span style="color: #D9001B;">请不要加水印</span>
                </div>
                <div style="margin-left: 20px;">3、 上传图片文件格式支持png，jpg和bmp</div>
                <div style="margin-left: 20px;">4、 营业执照图片大小500KB~5MB，尺寸最小为500px * 500px</div>
                <div style="margin-left: 20px;">5、 所有上传信息均会被妥善保管，不会用于其他商业用途或传输给 其他第三方</div>
                <div style="margin-left: 20px;">
                  6、
                  <span style="color: #D9001B;">部分信息来自AI检测，请认准核对，如有不符，请联系客服，客服电话：400-183-1833</span>
                </div>
              </div> -->
            </div>
          </el-form-item>
          <h3 v-if="this.userStatus==3" style="margin-left:150px;color:red">驳回原因：&nbsp;&nbsp;{{this.errorInfo}}</h3>
          <el-form-item>
            <el-button type="primary" :disabled='this.disabled' @click="submitForm('ruleForm')">提交认证资料</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div
        style="margin-top: 100px;display: flex;justify-content: center;align-items: center;margin-bottom: 100px;"
        v-if="perfect == false"
      >
        <div style="margin-right: 50px;">
          <img src="../assets/微信图片_20211015095545.png" alt style="width: 100px;height: 100px;" />
        </div>
        <div style="line-height: 40px;">
          <h4 v-if="this.userStatus==2">恭喜！您已完成企业实名认证</h4>
          <h4 v-if="this.userStatus==1">基础信息会在30分钟内帮您审核，请耐心等待。</h4>
          <h4 v-if="this.userStatus==1">客服电话：400-183-1833。</h4>
          <div
            style="font-weight: 400;font-style: normal;font-size: 14px;color: #999999;"
          >企业名称：{{this.message.com_name}}</div>
          <div
            style="font-weight: 400;font-style: normal;font-size: 14px;color: #999999;"
          >统一代码：{{this.message.com_code}}</div>
          <div
            style="font-weight: 400;font-style: normal;font-size: 14px;color: #999999;"
          >法人姓名：{{this.message.legal_person}}</div>
          <div
            style="font-weight: 400;font-style: normal;font-size: 14px;color: #999999;"
          >身份证号：{{this.message.card_code}}</div>
          <div
            style="font-weight: 400;font-style: normal;font-size: 14px;color: #999999;"
          >通讯地址：{{this.message.com_address}}</div>
          <div
            style="font-weight: 400;font-style: normal;font-size: 14px;color: #999999;"
          >开户银行：{{this.message.basic_account_name}}</div>
          <div
            style="font-weight: 400;font-style: normal;font-size: 14px;color: #999999;"
          >开户账号：{{this.message.basic_account_code}}</div>
          <div
            style="font-weight: 400;font-style: normal;font-size: 14px;color: #999999;"
             v-if="this.userStatus==2"
          >认证时间：{{this.message.create_date}}</div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
//import 《组件名称》 from '《组件路径》';
import Utils from "../components/util";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},

  // 组件名称
  name: "",

  // 这里存放数据
  data() {
    return {
      isperfect:false,
      disabled:false,
      ruleForm: {
        disabled:true,
        firm: "",
        code: "",
        name: "",
        area: "",
        bank: "",
        account: "",
        id: "",
      },
      rules: {
        firm: [{ required: true, message: "请输入企业名称", trigger: "blur" }],
        code: [{ required: true, message: "请输入统一社会信用代码", trigger: "blur" }],
        name: [{ required: true, message: "请输入法定代表人姓名", trigger: "blur" }],
        area: [{ required: true, message: "请输入通讯地址", trigger: "blur" }],
        bank: [{ required: true, message: "请输入开户银行", trigger: "blur" }],
        account: [
          { required: true, message: "请输入开户行账号", trigger: "blur" },
          { pattern: /^[0-9]\d*$/,message: '仅支持数字'},
        ],
        id: [
          { required: true, message: "请输入法人身份证号", trigger: "blur" },
          { min: 18, max: 18, message: "身份证号需为18位" },
        ],
      },
      data: {},
      data1: {},
      isShow: false,
      perfect: true,
      userStatus:'',
      message: {
        com_name: "",
        com_code: "",
        legal_person: "",
        card_code: "",
        com_address: "",
        basic_account_code: "",
        basic_account_name: "",
        create_date: "",
      },
      errorInfo:"",
    };
  },

  // 监听属性 类似于data概念
  computed: {},

  // 监控data中的数据变化
  watch: {},

  // 方法集合
  methods: {
    success(response) {
      if (response.status == 0) {
        this.$message({
          message: "上传成功",
          type: "success",
        });
        this.isShow = true;
        window.sessionStorage.setItem("yyzzImg", "yyzz");
        // window.sessionStorage.setItem("firmName", response.data.caukey_id[0]);
        // this.ruleForm.firm = response.data.enterpriseName[0];
        // this.ruleForm.code = response.data.creditCode[0];
        // this.ruleForm.name = response.data.legalPersonName[0];
        // this.ruleForm.area = response.data.enterpriseAddress[0];
        // window.sessionStorage.setItem(
        //   "con_name",
        //   response.data.enterpriseName[0]
        // );
        // window.sessionStorage.setItem(
        //   "socialCode",
        //   response.data.creditCode[0]
        // );
      // } else if (response.status==1) {
      //   this.$message({
      //     message: "上传失败,该企业名称已被占用",
      //     type: "error",
      //   });
      // } else if (response.status==2) {
      //   this.$message({
      //     message: "上传失败,该统一社会信用代码已被占用",
      //     type: "error",
      //   });
      // } else if (response.status==3) {
      //   this.$message({
      //     message: "上传失败,缺少文件类型",
      //     type: "error",
      //   });
      } else if (response.status==4) {
        this.$message({
          message: '上传失败：'+response.statusInfo,
          type: "error",
        });
      // } else if (response.status==-17) {
      //   this.$message({
      //     message: "上传失败,请上传jpg或png格式文件，请重新上传",
      //     type: "error",
      //   });
      // } else if (response.status==5) {
      //   this.$message({
      //     message: "上传失败,营业执照不合规，请重新上传",
      //     type: "error",
      //   });
      // } else if (response.status==6) {
      //   this.$message({
      //     message: "上传失败,已存在相同的企业信息",
      //     type: "error",
      //   });
      // } else if (response.status==7) {
      //   this.$message({
      //     message: "上传失败,识别信息有误，请重新上传",
      //     type: "error",
      //   });
      // } else if (response.status==-16) {
      //   this.$message({
      //     message: "上传失败,识别信息有误，请重新上传",
      //     type: "error",
      //   });
      // } else if (response.status==-11) {
      //   this.$message({
      //     message: "上传失败,缺少必要参数",
      //     type: "error",
      //   });
      }
    },

    successInfo(response) {
      if (response.status == 0) {
        this.$message({
          message: "上传成功",
          type: "success",
        });
        // this.disabled=false;
        window.sessionStorage.setItem("khxkz", "khxkz");
      } else {
        this.$message({
          message: "上传失败," + response.statusInfo,
          type: "error",
        });
      }
    },

    submitForm(formName) {
        this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!window.sessionStorage.getItem("yyzzImg") && this.userStatus=='4') {
            this.$message({
              message: "未上传营业执照",
              type: "warning",
            });
            return;
          }
          // if (!window.sessionStorage.getItem("khxkz") && this.userStatus=='4') {
          //   this.$message({
          //     message: "未上传开户许可证",
          //     type: "warning",
          //   });
          //   return;
          // }
        // setTimeout(() => {
          this.axios({
            method: "GET",
            url: "/newGuarantee.do?saveSuZhouData",
            params: {
              userName: window.sessionStorage.getItem("username"),
              phone: window.sessionStorage.getItem("phone"),
              enterpriseName:this.ruleForm.firm,
              creditCode:this.ruleForm.code,
              legalPersonName:this.ruleForm.name,
              enterpriseAddress:this.ruleForm.area,
              cardCode: this.ruleForm.id,
              basicAccountCode: this.ruleForm.account,
              basicAccountName: this.ruleForm.bank,
              from: 10,
            },
          }).then((res) => {
            if (res.data.status == 0) {
              this.$message({
                message: "企业信息完善成功",
                type: "success",
              });
              this.getInformationForNotCa();
              this.perfect = false;
              this.$router.go(0)
              Utils.$emit("demo");
            } else {
              this.$message({
                message: res.data.statusInfo,
                type: "error",
              });
            }
          });
        // }, 2000);
        } else {
          return false;
        }
      });

      // this.$refs[formName].validate((valid) => {
      //   if (valid) {
      //     if (!window.sessionStorage.getItem("yyzzImg")) {
      //       this.$message({
      //         message: "未上传营业执照",
      //         type: "warning",
      //       });
      //       return;
      //     }
      //     if (!window.sessionStorage.getItem("khxkz")) {
      //       this.$message({
      //         message: "未上传开户许可证",
      //         type: "warning",
      //       });
      //       return;
      //     }
      //     this.axios({
      //       method: "GET",
      //       url: "/newGuarantee.do?savaData",
      //       params: {
      //         userName: window.sessionStorage.getItem("username"),
      //         phone: window.sessionStorage.getItem("phone"),
      //         cardCode: this.ruleForm.id,
      //         basicAccountCode: this.ruleForm.account,
      //         basicAccountName: this.ruleForm.bank,
      //         from: 10,
      //       },
      //     }).then((res) => {
      //       if (res.data.status == 0) {
      //         this.$message({
      //           message: "企业信息完善成功",
      //           type: "success",
      //         });
      //         this.getInformationForNotCa();
      //         this.perfect = false;
      //         Utils.$emit("demo");
      //       } else {
      //         this.$message({
      //           message: res.data.statusInfo,
      //           type: "error",
      //         });
      //       }
      //     });
      //   } else {
      //     return false;
      //   }
      // });
    },

    getEnterpriseInformation() {
      this.axios({
        method: "GET",
        url: "/newGuarantee.do?existEnterpriseInfo",
        params: {
          handsetNum: window.sessionStorage.getItem("phone"),
          from: 10,
        },
      }).then((res) => {
        if(res.data.data.userStatus==2){
          this.userStatus=res.data.data.userStatus;
          this.perfect=false;
        }else if(res.data.data.userStatus==1){
          this.userStatus=res.data.data.userStatus;
          this.perfect=false;
        }else if(res.data.data.userStatus==3 && !res.data.data.apply){
          this.userStatus=4;
          this.isperfect=true;
        }else if(res.data.data.userStatus==3 && res.data.data.apply){
          this.errorInfo=res.data.data.errorInfo;
          this.userStatus=res.data.data.userStatus;
          this.isperfect=true;
          this.getInformationForNotCa2();
        };
        // if (res.data.data.apply && res.data.data.hasExist) {
        //   this.perfect = false;
        // }
      });
    },
    getInformationForNotCa2() {
      this.axios({
        method: "GET",
        url: "/newGuarantee.do?getInformationForNotCa",
        params: {
          userName: window.sessionStorage.getItem("username"),
          phone: window.sessionStorage.getItem("phone"),
          fromType:10,
        },
      }).then((res) => {
        console.log("Res",res);
        this.ruleForm.firm = res.data.data.com_name;
        this.ruleForm.code = res.data.data.com_code;
        this.ruleForm.name = res.data.data.legal_person;
        this.ruleForm.id = res.data.data.card_code;
        this.ruleForm.area = res.data.data.com_address;
        this.ruleForm.account = res.data.data.basic_account_code;
        this.ruleForm.bank = res.data.data.basic_account_name;
        // this.ruleForm.create_date = res.data.data.create_date;
      });
    },
    getInformationForNotCa() {
      this.axios({
        method: "GET",
        url: "/newGuarantee.do?getInformationForNotCa",
        params: {
          userName: window.sessionStorage.getItem("username"),
          phone: window.sessionStorage.getItem("phone"),
          fromType:10,
        },
      }).then((res) => {
        this.message.com_name = res.data.data.com_name;
        this.message.com_code = res.data.data.com_code;
        this.message.legal_person = res.data.data.legal_person;
        this.message.card_code = res.data.data.card_code;
        this.message.com_address = res.data.data.com_address;
        this.message.basic_account_code = res.data.data.basic_account_code;
        this.message.basic_account_name = res.data.data.basic_account_name;
        this.message.create_date = res.data.data.create_date;
      });
    },
  },

  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {},

  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.data = {
      CAKeyId: "",
      from: 10,
      userName: window.sessionStorage.getItem("username"),
      phone: window.sessionStorage.getItem("phone"),
      type: 1,
    };
    this.data1 = {
      CAKeyId: "",
      from: 10,
      userName: window.sessionStorage.getItem("username"),
      phone: window.sessionStorage.getItem("phone"),
      type: 2,
    };
    this.getEnterpriseInformation();
    this.getInformationForNotCa();
  },

  // 生命周期 - 创建之前
  beforeCreate() {},

  // 生命周期 - 挂载之前
  beforeMount() {},

  // 生命周期 - 更新之前
  beforeUpdate() {},

  // 生命周期 - 更新之后
  updated() {},

  // 生命周期 - 销毁之前
  beforeDestroy() {},

  // 生命周期 - 销毁完成
  destroyed() {},

  // 如果页面有keep-alive缓存功能，这个函数会触发
  activated() {},

  // keep-alive缓存离开之后触发
  deactivated() {},
};
</script>

<style scoped>
.enterprise {
  height: 500px;
}
.befor_1 {
  position: absolute;
  left: -93px;
  color: #f56c6c;
}
.befor_2 {
  position: absolute;
  left: -107px;
  color: #f56c6c;
}
</style>